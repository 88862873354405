import "../css/FillInTheBlankGame.css";
import GameSection from "../sections/GameSection";

function FillInTheBlankGame() {
  return (
    <>
      <GameSection></GameSection>
    </>
  );
}

export default FillInTheBlankGame;
