import React from "react";
import "../App.css"; // Varsayılan olarak App.css'i kullanıyoruz, gerekirse özel stil için düzenleyebilirsiniz

function Footer() {
  return (
    <footer className="footer">
        <div></div>
      <div className="footer-content">
        <span>© 2024 Memoreng by  <img src="/db1.png" alt=""/></span>
        

      </div>
      <div className="social-icons">
       
      </div>
    </footer>
  );
}

export default Footer;
